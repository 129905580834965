import React from 'react'
import { Navbar, Nav, Button, Container, Badge } from 'react-bootstrap'
import AuthenticationButton from './AuthenticationButton'

const LimitedNav = () => {
  return (
    <Navbar expand="lg" id='bg-nb' sticky='top'>
        <Container>
            <Navbar.Brand href="/">Readr</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="/home">Enter Now!</Nav.Link>
            </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
                <AuthenticationButton />
            </Navbar.Collapse>
        </Container>
    </Navbar>
  )
}

export default LimitedNav