import React, {useEffect, useState} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import OutNav from '../components/OutNav';
import { Row, Col, Image, Table } from 'react-bootstrap';
import RequestForm from '../components/RequestForm';
import AudioOff from '../components/AudioOff';
import "../css/home.css"
const axios = require('axios');

const HomePage = () => {
    const {user} = useAuth0();
    const {name, picture, email} = user;
    const [myReadings, setMyReadings] = useState([]);
    const [show, setShow] = useState(false);
    const [aud, setAud] = useState();

    let getMyReadings = async () => {
        await axios.get(`https://readr-testing.herokuapp.com/readings/${name}`).then(res => {
            setMyReadings(res.data);
        })
    }

    let go_to_audio = async (r_id) => {
        const r_url = await axios.post('https://readr-testing.herokuapp.com/get_audio', {
            id: r_id
        });
        const audio_url = JSON.parse(r_url.data.body).url
        return audio_url
    }

    useEffect(() => {
        getMyReadings();
    }, [myReadings])


  return (
    <div className='home'>
        <div className='in_header'>
            <OutNav/>
        </div>
        <div className='user'>
            <Row>
                <Col></Col>
                <Col>
                    <Image src={picture} roundedCircle></Image>
                    <h1>{name}</h1>
                </Col>
                <Col></Col>
            </Row>
        </div>
        <div className='request'>
            <div className='request_text'>
                <h1>Request a Reading!</h1>
                <p>Simply copy and paste your text into the box and hit submit</p>
            </div>
            <div className='request_form'>
                <RequestForm />
            </div>
        </div>
        <div className='my_readings'>
            <Row className='text-center'>
                <h1>My Readings</h1>
                <h4>Longer readings may take a few seconds to process.</h4>
            </Row>
            <Row className='text-center mt-5 mb-5' className='table_row'>
                <Table striped border hover className='read_table'>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Audio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {myReadings.slice(0).reverse().map((req, index) => (
                            <tr key={index}>
                                <td>{req.title}</td>
                                <td><AudioOff read_id={req.audio} title={req.title}/></td>
                            </tr>
                        ))}
                    </tbody>

                </Table>
            </Row>
        </div>
        <div className='circle1'></div>
        <div className='circle2'></div>
        <div className='circle3'></div>
        <div className='circle4'></div>
        <div className='circle5'></div>
    </div>
  )
}

export default HomePage