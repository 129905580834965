import React, {useState, useEffect} from 'react'
import { Navbar, Nav, Button, Container, Badge } from 'react-bootstrap'
import AuthenticationButton from './AuthenticationButton'
import { useAuth0 } from '@auth0/auth0-react'
const axios = require('axios')

const OutNav = () => {

    const {user} = useAuth0();
    const {name, picture, email} = user;

    return (
        <Navbar expand="lg" id='bg-nb' sticky='top'>
            <Container>
                <Navbar.Brand href="/">Readr</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/home">Home</Nav.Link>
                </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <AuthenticationButton className='auth_button'/>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        
    )
}

export default OutNav