import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router} from 'react-router-dom';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  <Router>
    <Auth0Provider
    domain='dev-m2yvip35.us.auth0.com'
    clientId='0PRCWBKk7oJgIKN0kk0F9Kt2lapQvptB'
    redirectUri={"https://readr.tech/home"}>
      <App />
    </Auth0Provider>
  </Router>,
  document.getElementById('root')
);
