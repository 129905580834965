import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import ProtectedComponent from './auth/ProtectedComponent';
import HomePage from './pages/HomePage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' exact element={<LandingPage />} />
        <Route path='/home' exact element={<ProtectedComponent component={HomePage} />} />
      </Routes>
    </div>
  );
}

export default App;
