import React, {useState} from 'react'
import {Button, Offcanvas, Toast} from 'react-bootstrap';
import AudioDisplay from './AudioDisplay';

const AudioOff = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);

  return (
    <>
    <Button onClick={handleOpen} className='listen'>Listen</Button>
    <Toast show={show} onClose={handleClose} className='audio_toast'>
        <Toast.Header className='toast_head'>
            <strong className='me-auto'>{props.title}</strong>
        </Toast.Header>
        <Toast.Body>
            <AudioDisplay read_id={props.read_id} />
        </Toast.Body>
    </Toast>
    </>
  )
}

export default AudioOff