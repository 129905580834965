import React, {useContext, useEffect, useState} from 'react'
import { Button, Form } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'

const RequestForm = () => {
    const {user} = useAuth0();
    const {name, picture, email} = user;
    const [reading, setReading] = useState();
    const [title, setTitle] = useState();
    const [audio, setAudio] = useState();
    const [genID, setGenID] = useState(null);
    const [len, setLen] = useState();


    let handleText = (e) => {
        setReading(e.target.value);
        setLen(e.target.value.length)
    }

    let handleTitle = (e) => {
      setTitle(e.target.value);
    }

    let divide_reading = (text, pieces) => {
      if (text.length <= 1000) {
        return pieces
      } else {
        let choppedText = text.slice(text.length - 1001)
        if (choppedText.indexOf(".") > -1) {
          let splitIndex = text.indexOf(".", text.length - 1001);
          pieces.unshift(text.slice(splitIndex + 1));
          let firstHalf = text.slice(0, splitIndex + 1);
          if (firstHalf.length > 1000) {
            return divide_reading(firstHalf, pieces)
          } else {
            pieces.unshift(firstHalf);
            return pieces
          }
        } else if (choppedText.indexOf(";") > -1) {
          let splitIndex = text.indexOf(",", text.length - 1001);
          pieces.unshift(text.slice(splitIndex + 1));
          let firstHalf = text.slice(0, splitIndex + 1);
          if (firstHalf.length > 1000) {
            return divide_reading(firstHalf, pieces)
          } else {
            pieces.unshift(firstHalf);
            return pieces
          }
        } else if (choppedText.indexOf(":") > -1) {
          let splitIndex = text.indexOf(":", text.length - 1001);
          pieces.unshift(text.slice(splitIndex + 1));
          let firstHalf = text.slice(0, splitIndex + 1);
          if (firstHalf.length > 1000) {
            return divide_reading(firstHalf, pieces)
          } else {
            pieces.unshift(firstHalf);
            return pieces
          }
        } else if (choppedText.indexOf(",") > -1) {
          let splitIndex = text.indexOf(",", text.length - 1001);
          pieces.unshift(text.slice(splitIndex + 1));
          let firstHalf = text.slice(0, splitIndex + 1);
          if (firstHalf.length > 1000) {
            return divide_reading(firstHalf, pieces)
          } else {
            pieces.unshift(firstHalf);
            return pieces
          }
        } else {
          let splitIndex = text.indexOf(" ", text.length - 1001);
          pieces.unshift(text.slice(splitIndex + 1));
          let firstHalf = text.slice(0, splitIndex + 1);
          if (firstHalf.length > 1000) {
            return divide_reading(firstHalf, pieces)
          } else {
            pieces.unshift(firstHalf);
            return pieces
          }
        }
        
      }
    }

    function makeid() {
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    
      for (var i = 0; i < 100; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    
      return text;
    }

    let updated_submit = async (e) => {
      e.preventDefault();
      if (len <= 1000) {
        const gen_id = await axios.post("https://readr-testing.herokuapp.com/generate_id", {
          reading: reading
        });
        const read_id = JSON.parse(gen_id.data.body).id;
        await axios.post("https://readr-testing.herokuapp.com/readings/add", {
          submitted_by: name,
          title: title,
          audio: read_id
        })
      } else {
        let start = []
        let ar = divide_reading(reading, start)
        let combinedAudios = ""
        for (const element of ar) {
          console.log(element)
          console.log(element.length)
          const gen_id = await axios.post("https://readr-testing.herokuapp.com/generate_id", {
          reading: element
          });
          const read_id = JSON.parse(gen_id.data.body).id;
          if (combinedAudios.length === 0) {
            combinedAudios += read_id
          } else {
            combinedAudios += "," + read_id
          }
        }
        console.log(combinedAudios)
        console.log(combinedAudios.split(","))
        await axios.post('https://readr-testing.herokuapp.com/readings/add', {
        submitted_by: name,
        title: title,
        audio: combinedAudios
        })

      }
    }

    let handleSubmit = async (e) => {
      e.preventDefault();
      let url = null;
      const gen_id = await axios.post('https://readr-testing.herokuapp.com/generate_id',{
        reading: reading
      });
      const read_id = JSON.parse(gen_id.data.body).id;
      // console.log("pre sleep")
      // await new Promise(r => setTimeout(r, 10000));
      // console.log("post sleep")
      // const r_url = await axios.post('https://readr-testing.herokuapp.com/get_audio', {
      //   id: read_id
      // });
      // const audio_url = JSON.parse(r_url.data.body).url
      await axios.post('https://readr-testing.herokuapp.com/readings/add', {
        submitted_by: name,
        title: title,
        audio: read_id
      })
    

    }

  return (
    <Form onSubmit={updated_submit}>
      <Form.Group className='mb-3'>
        <Form.Label>Title</Form.Label>
        <Form.Control type='text' name='title' onChange={handleTitle} className='form_input'/>
      </Form.Group>
      <Form.Group className='mb-3' controlId='exampleFrom.ControlTextarea1'>
        <Form.Label>Text to be read</Form.Label>
        <Form.Control as="textarea" rows={5} name='reading' onChange={handleText} className='form_input'/>
      </Form.Group>
      <ul>
        {(len > 0) && (len <= 10000) && <li className='text-success'>Characters: {len} / 10000</li>}
        {(!len) || (len > 10000) && <li className='text-danger'>Characters: {len} / 10000</li>}
      </ul>
      <Button variant='outline-primary' id='comp' type='submit' disabled={!len || len > 10000} className='listen'>Submit Request</Button>
    </Form>
  )
}

export default RequestForm