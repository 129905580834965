import React from 'react'
import { Spinner } from 'react-bootstrap';

const Loading = () => {
  return (
    <div id='ctrd'>
        <Spinner animation='grow' />
    </div>
  )
}

export default Loading;