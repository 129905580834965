import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { Button } from 'react-bootstrap';

const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Button variant='outline-primary' onClick={() => {loginWithRedirect({
        screen_hint: "signup"
    })}}>Sign Up</Button>
  )
}

export default SignUpButton