import React, {useState, useEffect} from 'react'
import { Row, Col } from 'react-bootstrap'
import concatAudioBuffers from 'concat-audio-buffers';
import toWav from 'audiobuffer-to-wav';
import Loading from './loading';
const axios = require('axios');
const arrayBufferToAudioBuffer = require('arraybuffer-to-audiobuffer');

const AudioDisplay = (props) => {
    const [audio, setAudio] = useState();
    const [spin, setSpin] = useState(true);

    function useBuffer(error, combinedBuffer) {
        if (error) {
          console.log(error);
        } else {
          let blob = new Blob([toWav(combinedBuffer)], { type: 'audio/wav' });
          // Then, make this blob downloadable or make it
          // the src for an <audio> element or whatever you want.
          //console.log(blob)
          let blobUrl = window.URL.createObjectURL(blob)
          setAudio(blobUrl)
          setSpin(false)
        }
      }

    let get_audio = async () => {
        await axios.post('https://readr-testing.herokuapp.com/get_audio', {
            id: props.read_id
        }).then(res => {
            setAudio(JSON.parse(res.data.body).url)
            //console.log(JSON.parse(res.data.body).url);
        })
    }

    let updated_get_audio = async () => {
        if (props.read_id.indexOf(",") > -1) {
            let ids = props.read_id.split(",")
            let urls = []
            let url;
            for await (const read_id of ids) {
                await axios.post('https://readr-testing.herokuapp.com/get_audio', {
                    id: read_id
                }).then(res => {
                    // urls.push(JSON.parse(res.data.body).url)
                    url = JSON.parse(res.data.body).url
                });
                const response = await axios.get(url, {
                    responseType: 'arraybuffer', // <- important param
                 });
                await arrayBufferToAudioBuffer(response.data)
                .then(audioBuffer => {
                urls.push(audioBuffer)
                })
                //console.log(urls)
                
            }
            //TODO: Combine these audio urls into one big file and then set audio
            // console.log(urls)
            concatAudioBuffers(
                urls, // AudioBuffers
                2, // Desired number of channels, converts to stereo if input is mono and this value is 2
                useBuffer
            );
        } else {
            await axios.post('https://readr-testing.herokuapp.com/get_audio', {
                id: props.read_id
            }).then(res => {
                setAudio(JSON.parse(res.data.body).url)
                setSpin(false)
                //console.log(JSON.parse(res.data.body).url);
            })
        }
    }

    useEffect(() => {
        updated_get_audio();
    }, [])


  return (
    <div>
        {spin && <Loading />}
        { !spin && 
        <Row>
            <audio controls src={audio}>Audio not supported</audio>
        </Row>}
    </div>
  )
}

export default AudioDisplay