import React from 'react'
import SignUpButton from '../components/SignUpButton'
import LimitedNav from '../components/LimitedNav'
import "../css/landing.css"

const LandingPage = () => {
  return (
      <>
        <video autoplay="autoplay" muted loop src={process.env.PUBLIC_URL + "/background.mov"}id="bg"></video>
        <div className='lim_nav'>
            <LimitedNav />
        </div>
        <div className='logo'>
            <img src={process.env.PUBLIC_URL + "/readrwhite.png"} alt="Readr Logo" width="650" height="650"></img>
        </div>
        <div className='cont'>
            <h1>Reading Reimagined</h1>
            <p>Turn your readings into narrations and listen on the go</p>
            <SignUpButton />
        </div>
    </>
  )
}

export default LandingPage